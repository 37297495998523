<template>
  <div>
    <!-- PIPELINE -->
    <div class="flex-pipe" v-if="!isMobile">
      <div class="acoes">
        <div class="cont-acoes">
          <div class="acao-home" v-if="!isIframe">
            <router-link to="/dynamicRoute/home">
              <img
                v-if="isGreenn"
                style="object-fit: contain"
                height="40px"
                width="40px"
                src="@/assets/greenn_sales/SIMBOLO_.png"                
                alt="add-icon"
              />
              <img
                v-else
                style="object-fit: contain"
                height="40px"
                width="40px"
                src="@/assets/img/G-digital.png"
                alt="add-icon"
              />
            </router-link>
          </div>
          <div class="acoes-select">
            <BaseSelect
              :selectModel="selectedPipelineItem"
              :array="pipelines"
              trackname="title"
              class="select-pipe"
              track-by="id"
              placeholder="Pesquisar"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :allow-empty="false"
              @callback="newPagePipelines('first', null, $event, false)"
              @change="selectedPipelineItem = $event"
              :watch="true"
            >
              <span style="font-size: 13px !important" slot="noResult"
                >Nenhum pipeline encontrado.</span
              >
            </BaseSelect>
          </div>
          <div class="acoes-input">
            <div class="flex">
              <svg
                v-if="!pesquisar_lead"
                class="acoes-busca anima_search"
                @click="focusInputSearch"
                style="cursor: pointer; margin-right: 20px"
                v-b-tooltip.hover.bottom
                title="Pesquisar lead"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 19L14.65 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                  stroke="#141414"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <!-- <img
                class="acoes-busca anima_search"
                @click="pesquisar_lead = !pesquisar_lead"
                style="cursor: pointer; margin-right: 20px"
                src="@/assets/icons/new-search.svg"
                alt="search-icon"
                v-b-tooltip.hover.bottom
                title="Pesquisar lead"
              /> -->
              <input
                data-anima="top"
                v-if="pesquisar_lead"
                type="text"
                placeholder="Pesquisar lead"
                class="input-busca"
                @input="debounce"
                v-model="search"
                ref="focusInput"
              />
              <div style="position: relative">
                <svg
                  @click="pesquisar_lead = !pesquisar_lead"
                  v-if="pesquisar_lead"
                  class="acoes-busca anima_search"
                  style="
                    cursor: pointer;
                    position: absolute;
                    left: -5px;
                    top: -7px;
                    width: 14.7px;
                    height: 14.7px;
                  "
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5339 15.8416L11.9803 12.2879M13.9001 7.6722C13.9001 11.2816 10.974 14.2077 7.36458 14.2077C3.75514 14.2077 0.829102 11.2816 0.829102 7.6722C0.829102 4.06275 3.75514 1.13672 7.36458 1.13672C10.974 1.13672 13.9001 4.06275 13.9001 7.6722Z"
                    stroke="#81858E"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <!-- <img
                  @click="pesquisar_lead = !pesquisar_lead"
                  v-if="pesquisar_lead"
                  class="acoes-busca"
                  style="
                    cursor: pointer;
                    position: absolute;
                    left: -5px;
                    top: -7px;
                    width: 14.7px;
                    height: 14.7px;
                  "
                  src="@/assets/img/icons/search.svg"
                  alt="search-icon"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="cont-acoes" :style="isIframe ? 'margin-right: 9px;' : ''">
          <b-spinner
            label="Loading..."
            v-show="loading_fetching"
            v-b-tooltip="{
              title: 'Carregando leads',
              placement: 'top',
            }"
          ></b-spinner>
          <div
            v-if="lead_click.length >= 1"
            class="drop-container"
            data-anima="top"
          >
            <div class="qntd-lead">
              <p>{{ lead_click.length }}</p>
              <p v-if="lead_click.length === 1">Lead</p>
              <p v-if="lead_click.length > 1">Leads</p>
            </div>
            <b-dropdown id="dropContainer" left>
              <template #button-content>
                <img
                  src="@/assets/img/icons/dots-black.svg"
                  alt="dots"
                  v-b-tooltip.hover.bottom
                  title="Escolher ação"
                />
              </template>
              <b-dropdown-item
                @click="openModalWhatsCtrl('ModalMnsgWhatsCtrl', lead_click)"
                >Enviar mensagem no WhatsApp</b-dropdown-item
              >
              <b-dropdown-item @click="openModal('MoverLeadCtrl')"
                >Mover leads</b-dropdown-item
              >
              <b-dropdown-item
                class="red-item"
                @click="removerLeadsCtrl(lead_click)"
              >
                Remover leads
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <svg
            @click="reloadCRM()"
            v-b-tooltip="{
              title: `Última atualização: ${time_get}`,
              placement: 'top',
            }"
            style="cursor: pointer"
            class="acoes-relatorio"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="#141414"
            viewBox="0 0 256 256"
          >
            <path
              d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"
            ></path>
          </svg>
          <!-- <div class="acoes-lead-companie" @click="openModal('filter-pipe')">
            <img
              src="@/assets/icons/new-filtro.svg"
              alt="icon"
              v-b-tooltip.hover.bottom
              title="Filtros"
            />
          </div> -->
          <div class="acoes-filtro" @click="openModal('filter-pipe')">
            <img
              src="@/assets/icons/new-filtro.svg"
              alt="icon"
              v-b-tooltip.hover.bottom
              title="Filtros"
            />
          </div>
          <!-- HIDDEN-FEATURE -->
          <div class="cont-acoes" style="gap: 50px; overflow: hidden; max-width: 20px; transition: all 0.8s; height: 90px;" :style="showMoreActions ? 'max-width: 450px !important;' : ''">
            <img
              src="@/assets/img/icons/dots.svg"
              alt="dots"
              style="filter: brightness(0); cursor: pointer"
              @click="showMoreActions = !showMoreActions"
            />
            <div
              @click="(colors = !colors), setColors()"
              style="cursor: pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#030303"
                viewBox="0 0 256 256"
              >
                <path
                  d="M200.77,53.89A103.27,103.27,0,0,0,128,24h-1.07A104,104,0,0,0,24,128c0,43,26.58,79.06,69.36,94.17A32,32,0,0,0,136,192a16,16,0,0,1,16-16h46.21a31.81,31.81,0,0,0,31.2-24.88,104.43,104.43,0,0,0,2.59-24A103.28,103.28,0,0,0,200.77,53.89Zm13,93.71A15.89,15.89,0,0,1,198.21,160H152a32,32,0,0,0-32,32,16,16,0,0,1-21.31,15.07C62.49,194.3,40,164,40,128a88,88,0,0,1,87.09-88h.9a88.35,88.35,0,0,1,88,87.25A88.86,88.86,0,0,1,213.81,147.6ZM140,76a12,12,0,1,1-12-12A12,12,0,0,1,140,76ZM96,100A12,12,0,1,1,84,88,12,12,0,0,1,96,100Zm0,56a12,12,0,1,1-12-12A12,12,0,0,1,96,156Zm88-56a12,12,0,1,1-12-12A12,12,0,0,1,184,100Z"
                ></path>
              </svg>
            </div>
            <!-- HIDDEN-FEATURE -->
            <!-- <div class="acoes-visualizar">
              <img
                src="@/assets/icons/companie.svg"
                alt="icon"
                v-b-tooltip.hover.bottom
                @click="changeCompanylead = !changeCompanylead"
                v-if="!changeCompanylead"
                title="Visualizar empresas"
              />
              <img
                src="@/assets/icons/lead-view.svg"
                alt="icon"
                @click="changeCompanylead = !changeCompanylead"
                v-if="changeCompanylead"
                v-b-tooltip.hover.bottom
                title="Visualizar leads"
              />
            </div> -->
            <div class="listagem-pipe" @click="list_type = !list_type">
              <img
                src="@/assets/img/icons/menu.svg"
                alt="dots"
                :style="`${
                  list_type
                    ? 'transform: rotate(90deg);transition:all .3s'
                    : 'transition:all .3s'
                }`"
                v-b-tooltip.hover.bottom
                :title="`${
                  list_type ? 'Visualizar em coluna' : 'Visualizar em lista'
                }`"
              />
            </div>
            <div
              class="acoes-pipe"
              @click="openModal('ModalPipelineAdicionar')"
              v-if="!isIframe && level !== 'seller'"
            >
              <img
                src="@/assets/icons/add.svg"
                alt="icon"
                v-b-tooltip.hover.bottom
                title="Novo CRM"
              />
            </div>
            <div
              class="acoes-config"
              @click="openModal('ModalPipelineEditar')"
              v-if="level !== 'seller'"
            >
              <img
                src="@/assets/icons/config.svg"
                alt="icon"
                v-b-tooltip.hover.bottom
                title="Configurações"
              />
            </div>
            <img
              v-if="!isIframe"
              class="acoes-relatorio"
              src="@/assets/img/icons/relatorio-dark.svg"
              alt="icon"
              v-b-tooltip.hover.bottom
              title="Histórico de CRM"
              @click="
                $router.push({
                  name: `relatorio-crm`,
                  query: { id: selectedPipeline, name: selectedPipelineItem.title },
                })
              "
            />
          </div>
          <!-- HIDDEN-FEATURE -->
          <!-- <svg
            class="acoes-hidden"
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.73 20C10.5542 20.3031 10.3018 20.5547 9.99823 20.7295C9.69461 20.9044 9.35038 20.9965 9 20.9965C8.64962 20.9965 8.30539 20.9044 8.00177 20.7295C7.69816 20.5547 7.44581 20.3031 7.27 20M15 7C15 5.4087 14.3679 3.88258 13.2426 2.75736C12.1174 1.63214 10.5913 1 9 1C7.4087 1 5.88258 1.63214 4.75736 2.75736C3.63214 3.88258 3 5.4087 3 7C3 11.1262 1.9576 13.5151 1.10171 14.7812C0.824647 15.191 1.15668 16 1.65139 16H16.3486C16.8433 16 17.1754 15.191 16.8983 14.7812C16.0424 13.5151 15 11.1262 15 7Z"
              stroke="#000000"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> -->

          <div class="acoes-user" v-if="!isIframe">
            <img
              v-if="foto_usuario"
              :src="changeUrl(foto_usuario)"
              class="user_logged"
            />
            <img
              v-else
              class="user_logged"
              src="@/assets/icons/no-seller.svg"
            />
          </div>
        </div>
      </div>
      <div
        class="container-sellers"
        v-if="(sellerConfig && sellerConfig.sellerCanSeeLeadWithoutSeller == true) || level != 'seller'"
      >
        <div class="sellers">
          <b-col
            v-if="(sellerConfig && sellerConfig.sellerCanSeeLeadWithoutSeller == true) || level != 'seller'"
            @click="filterSelectedSeller({ id: '0' })"
            class="seller"
            :class="{ sellerAtivo: sellerSelected === '0' }"
            v-b-tooltip.hover.bottom
            title="Não atribuído"
          >
            <div class="container-seller-img">
              <img src="@/assets/icons/no-seller2.svg" alt="seller" />
            </div>

            <div
              class="seller-no-ft2"
              :class="{ sellerAtivoFoco: sellerSelected === '0' }"
            >
              <h3>NA</h3>
            </div>

            <p class="name-seller">Não Atr..</p>
          </b-col>
        </div>

        <hr
          v-if=" (sellerConfig && sellerConfig.sellerCanSeeLeadWithoutSeller == true) || level != 'seller'"
          class="spacer"
        />

        <div v-if="level != 'seller'">
          <div class="sellers" v-for="(item, index) in users" :key="index">
            <b-col
              @click="filterSelectedSeller(item)"
              class="seller"
              :class="{ sellerAtivo: sellerSelected === item.id }"
              v-b-tooltip.hover.bottom
              :title="getMetaEmail(item)"
              v-if="item && item.id != 0"
            >
              <div class="container-seller-img">
                <img
                  alt="seller"
                  :src="allMetaphotoUser[index].photo"
                  v-if=" allMetaphotoUser[index] && allMetaphotoUser[index].photo"
                />
              </div>

              <div
                class="seller-no-ft"
                :class="{ sellerAtivoFoco: sellerSelected === item.id }"
              >
                <h3 v-if="!getMetaPicture(item.metas, item)">
                  {{ getMetaInitial(item) }}
                </h3>
              </div>

              <p class="name-seller">{{ getMetaName(item) }}</p>
            </b-col>
          </div>
        </div>

        <div class="sellers" v-if="level == 'seller'">
          <b-col
            @click="filterSelectedSeller(user)"
            class="seller"
            :class="{ sellerAtivo: sellerSelected === user.id }"
            v-b-tooltip.hover.bottom
            :title="getMetaEmail(user)"
          >
            <div class="container-seller-img">
              <img
                alt="seller"
                :src="getMetaPicture(user.metas, item)"
                v-if="getMetaPicture(user.metas, item)"
              />
            </div>

            <div
              class="seller-no-ft"
              :class="{ sellerAtivoFoco: sellerSelected === user.id }"
            >
              <h3>{{ getMetaInitial(user) }}</h3>
            </div>

            <p class="name-seller">{{ getMetaName(user) }}</p>
          </b-col>
        </div>
      </div>
      <!-- PIPELINE COLUNAS -->
      <div v-show="list_type">
        <div class="base background-vertical" :class="{'off-colors' : !colors, 'w-100' : level == 'seller'}" v-if="!loading">
          <div class="background-header"></div>
          <div
            class="colx"
            v-for="(item, index_column) in getSelectedPipeline"
            :key="index_column"
            :class="filterTagColor(item)"
          >
            <div class="headerx" :class="filterTagColor(item)">
              <div style="display: flex; flex-direction: column">
                <h1>
                  {{
                    filterTag(item).length > 15
                      ? filterTag(item).substr(0, 15) + "..."
                      : filterTag(item)
                  }}
                </h1>
                <div
                  @mouseenter="
                    (detailProduct = index_column), showProductDetail(item)
                  "
                  @mouseleave="detailProduct = -1"
                  style="position: relative"
                >
                  <span class="moneyTotal" v-if="item">{{
                    index_column === getSelectedPipeline.length - 1 ? "Tag de saída" : moneyTotal(item)
                  }}</span>
                  <div
                    v-if="detailProduct == index_column"
                    class="drop-product"
                  >
                    <ModalProducts
                      :label="currentLabel"
                      :value="currentValue"
                    />
                  </div>
                </div>
              </div>

              <b-dropdown
                v-if="all_leads && all_leads['lista_' + item].data.length"
                id="dropView"
                class="drop-info"
                right
              >
                <template #button-content>
                  <img
                    src="@/assets/img/icons/dots-white.svg"
                    alt="dots"
                    v-b-tooltip.hover.right
                    title="Menu do board"
                  />
                </template>
                <b-dropdown-item @click="openModal('ModalMnsgWhats', item)"
                  >Enviar mensagem no WhatsApp</b-dropdown-item
                >
                <b-dropdown-item @click="openModalMove(item)"
                  >Mover leads</b-dropdown-item
                >
                <b-dropdown-item v-if="level !== 'seller'" @click="editTag('add-tag', item)">
                  Editar tag
                </b-dropdown-item>
                <b-dropdown-item
                  class="red-item"
                  @click="removerLeadsBoard(item)"
                >
                  Remover Leads
                </b-dropdown-item>
              </b-dropdown>

              <div>
                <div class="numberLeads">
                  {{
                    all_leads &&
                    all_leads["lista_" + item] &&
                    all_leads["lista_" + item].total
                      ? all_leads["lista_" + item].total
                      : 0
                  }}
                </div>
              </div>
            </div>
            <div
              class="lds-ellipsis"
              v-if="loading_columns[index_column].loading"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            
            <draggable  
              v-if="!loading_columns[index_column].loading && all_leads"
              class="list-group" 
              ghost-class="ghost"
              :data-id="item" 
              :list="all_leads['lista_' + item].data" 
              group="people" 
              @end="changeLead"
              draggable=".list-group-item"
            >
              <CardPipeline
                :data-lead="lead.id"
                v-for="(lead, index) in all_leads['lista_' + item].data" 
                :key="`lead_${lead.id}_${index}`" 
                :item="item"
                :data-column-lead="item"
                :index="index"
                :lead="lead"
                :level="level"
                :sellerConfig="sellerConfig"
                :pipeline_config="pipeline_config"
                :lead_click="lead_click"
                :pipelines="pipelines"
                :ctrl_item="ctrl_item"
                :tag="tag"
                :selectedPipeline="selectedPipeline"
                :sellers="sellers"
                :user="user"
                :totalPagina="totalPagina"
                :configSellerCanTransferLead="configSellerCanTransferLead"
                :currentChecks="currentChecks"
                :isIframe="isIframe"
                @returnPostMensage="emitReturnPostMensage"
                @update:leadsBoard="leadsBoard = $event"
                @update:seller_selected_card="seller_selected_card = $event"
                @update:valor_tag_colum="valor_tag_colum = $event"
                @update:totalPagina="totalPagina = $event"
                @update:configSellerCanTransferLead="configSellerCanTransferLead = $event"
                @update:id_lead_selected="selectIdLeadAndOpenLeadModal($event)"
                @update:reasonType="reasonType = $event"
                @update:selected_lead_action="selected_lead_action = $event"
                @reloadEspecificColumn="getReloadEspecificColumn"
                v-observe-visibility="index === all_leads['lista_' + item].data.length - 7 ? onVisibilityChange : false"
              >
              </CardPipeline>
              <div v-show="showLoadingCards[index_column]" v-for="fake in 15" :key="`${fake}FakeDivOn${item}`"
                class="list-group-item-fake shine" draggable="false">
                <div></div>
              </div>
              <div class="add-lead-bottom" @click="openModal('ModalAdicionarLead', item)">
                <img src="@/assets/icons/add2.svg" alt="add-icon" />
              </div>
                <div :style="missingHeight[index_column]" :data-column-lead="item" draggable="false"
                v-observe-visibility="onVisibilityChange">
                <div style="height: inherit;"></div>
              </div>
            </draggable>
          </div>
        <div
          v-if="getSelectedPipeline.length < 8 && level !== 'seller'"
          class="colx add-colx"
        >
          <div class="headerx">
            <div style="display: flex; gap: 10px">
              <span>
                <img
                  @click="openAddTag()"
                  src="@/assets/icons/add.svg"
                  alt="icon"
                  style="filter: invert(100%) brightness(500%)"
                />
              </span>
              <h1 @click="openAddTag()">Adicionar tag</h1>
            </div>
          </div>
          <div
            class="add-tag"
            v-if="
              pipeline_config['tag_lead_pipeline'] != 'false' ? true : false
            "
          >
            <img
              @click="openAddTag()"
              src="@/assets/icons/add2.svg"
              alt="add-icon"
            />
          </div>
        </div>
        </div>
        <div
          style="position: absolute; top: 76px; height: calc(100vh - 76px); display: flex; align-items: center; left: 0; width: calc(100vw - 75px); justify-content: center;"
          v-if="loading"
        >
          <b-spinner variant="dark"></b-spinner>
        </div>
      </div>
      <!-- PIPELINE LISTA -->
      <div class="base" :class="{'w-100' : level == 'seller'}" style="overflow: hidden" v-show="!list_type">
        <!-- HEADER -->
        <div class="pipe_listagem">
          <div class="lds-ellipsis" v-if="loading">
            <div style="background: #ccc"></div>
            <div style="background: #ccc"></div>
            <div style="background: #ccc"></div>
            <div style="background: #ccc"></div>
          </div>
          <!-- HEADER -->
          <b-row
            class="Table-header mb-1"
            v-if="!isMobile && !loading"
          >
            <b-col cols="1" style="padding: 0 0 0 40px"> Qualif </b-col>

            <b-col
              cols="2"
              class="d-none d-md-block"
              style="padding: 0 0 0 22px"
            >
              Lead
            </b-col>

            <b-col cols="1" style="padding-left: 18px"> Etapa </b-col>

            <b-col
              cols="1"
              class="d-none d-md-block"
              style="padding-left: 18px"
            >
              Tag
            </b-col>

            <b-col cols="1" class="d-none d-md-block" style="padding-left: 15px"
              >Follow-Up</b-col
            >

            <b-col cols="2" class="d-none d-md-block" style="padding-left: 15px"
              >Origem</b-col
            >

            <b-col cols="1" class="d-none d-md-block" style="padding-left: 13px"
              >Entrada</b-col
            >

            <b-col cols="1" style="padding-left: 9px"> Vendedor </b-col>

            <b-col cols="1" style="padding-left: 9px"> {{ show_origin_form ? 'Formulário de origem' :  'Último cadastro' }} </b-col>

            <b-col cols="1" style="padding: 0 0 0 55px"> Ver </b-col>
          </b-row>
          <!-- BODY -->
          <template
            v-if="
              getSelectedPipeline &&
              getSelectedPipeline.length &&
              all_leads &&
              all_leads !== null
            "
          >
            <div
              v-for="(element, index_column) in getSelectedPipeline"
              :key="index_column"
            >
              <div
                class="Table-body"
                v-for="(item, index_list) in all_leads['lista_' + element].data"
                :key="`lead_${item.id}_${index_list}`"
                :data-table-index="index_list"
              >
                <b-row :class="getStatus(item.status).class">
                  <!-- id -->
                  <b-col
                    cols="1"
                    style="width: 50px; position: relative; padding: 30px"
                    class="d-none d-flex"
                  >
                    <div style="padding-left: 20px">
                      <svg
                        style="position: absolute; left: 25px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1436_593)">
                          <path
                            d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z"
                            :stroke="filterLeadscore(item.leadscore)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1436_593">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ item.leadscore ? item.leadscore : 0 }}º
                    </div>
                  </b-col>
                  <!-- lead -->
                  <b-col cols="2" class="d-none d-md-block dados-cliente">
                    <div 
                      v-if="item.name">
                      {{
                        item.name && item.name.length > 25
                          ? item.name.substr(0, 25) + "..."
                          : item.name
                      }}
                    </div>

                    <div
                      v-if="item.email"
                      :id="item.email"
                      v-b-tooltip="{
                        title: item.email,
                        placement: 'top',
                      }"
                    >
                      {{
                        item.email && item.email.length > 25
                          ? item.email.substr(0, 25) + "..."
                          : item.email
                      }}
                    </div>


                    <div v-if="item.phone">
                      <a v-if="!isIframe"
                        :href="formatTel(item.phone)"
                        target="_blank"
                        class="whats-cliente"
                        >{{ item.phone
                        }}<img src="@/assets/icons/whats.svg" alt=""
                      /></a>
                      <div
                        v-else
                        @click.stop="openLeadWpp(item.phone)"
                        style="cursor: pointer;"
                        class="whats-cliente balanc-decoration ">
                          {{ item.phone }} <img src="@/assets/icons/whats.svg" alt=""/>
                      </div>
                    </div>
                  </b-col>
                  <!-- Etapa -->
                  <b-col cols="1" class="d-none d-md-block">
                    {{ filterTag(element) }}
                  </b-col>
                  <!-- tag -->
                  <b-col cols="1" class="d-none d-md-block">
                    <span v-if="!item.tags.length" style="font-size: 12px"
                      >Sem tag</span
                    >
                    <span
                      :class="
                        'tag-label ' + filterTagTable(item.tags).color + '-tag'
                      "
                      v-else
                      >{{
                        filterTagTable(item.tags)
                          ? filterTagTable(item.tags).name
                          : "Sem tag"
                      }}</span
                    >
                  </b-col>
                  <!-- follow-up -->
                  <b-col cols="1" class="d-none d-md-block">
                    <span v-if="item.follow_up">{{
                      item && item.follow_up | datetime
                    }}</span>
                    <span v-else>Sem Follow-Up</span>
                  </b-col>
                  <!-- origem -->
                  <b-col
                    v-if="
                      item.origin !== 'Cadastro manual' &&
                      item.origin !== 'Cadastro pipeline' &&
                      item.origin !== 'Importacao' &&
                      item.origin !== 'LeadAds' &&
                      item.origin !== 'new-checkout' &&
                      item.origin !== 'G Whats'
                    "
                    cols="2"
                    class="d-none d-md-block"
                  >
                    <span v-if="item && item.views && item.views.length"
                      >Pag: {{ filterPage(item.views) }}<br
                    /></span>
                    <span v-else>Pag: Desconhecido<br /></span>
                    <span v-if="item.forms"
                      >Form:
                      {{
                        filterForm(item.forms) && filterForm(item.forms).title
                          ? filterForm(item.forms, true).title
                          : ""
                      }}<br
                    /></span>
                    <span v-else>Form: Desconhecido<br /></span>
                    <span v-if="item.journey">Jornada: {{ item.journey }}</span>
                  </b-col>

                  <b-col v-else-if="item.origin === 'Cadastro manual'">
                    <span>Cadastro manual</span>
                  </b-col>

                  <b-col v-else-if="item.origin === 'new-checkout'">
                    <span>Greenn Checkout</span>
                  </b-col>

                  <b-col v-else-if="item.origin === 'Importacao'">
                    <span>Importação</span>
                  </b-col>

                  <b-col v-else-if="item.origin === 'Cadastro pipeline'">
                    <span>Cadastro Pipeline</span>
                  </b-col>

                  <!-- entrada -->
                  <b-col cols="1" class="d-none d-md-block">
                    {{
                      item.recadastro
                        ? item.recadastro
                        : item.created_at | datetimem
                    }}
                  </b-col>
                  <!-- vendedor -->
                  <b-col
                    style="cursor: pointer;"
                    cols="1"
                    class="d-none d-md-block"
                    @click="openModalCardSeller2('ModalEditarSeller', item, 'reload_list')"
                  >
                    {{
                      filterSeller(item.user_id)
                        ? filterSeller(item.user_id)
                        : "Sem Vendedor"
                    }}
                  </b-col>
                  <!-- ultimo cadastro -->
                  <b-col
                    cols="1"
                    id="ultimo-cadastro"
                    class="d-none d-md-block"
                  >
                    <span v-if="item.forms" style="word-break: break-word"
                      >{{
                        filterForm(item.forms, show_origin_form) && filterForm(item.forms, show_origin_form).title
                          ? filterForm(item.forms, show_origin_form).title
                          : ""
                      }}<br
                    /></span>
                    <span
                      style="font-weight: 500; font-size: 13px"
                      v-if="
                        filterForm(item.forms, show_origin_form) && filterForm(item.forms, show_origin_form).title
                          ? filterForm(item.forms, show_origin_form).title
                          : 'Desconhecido'
                      "
                      >{{
                        filterForm(item.forms, show_origin_form) &&
                        filterForm(item.forms, show_origin_form).assoc_date
                          ? filterForm(item.forms, show_origin_form).assoc_date
                          : item.assoc_date | datetime
                      }}</span
                    >
                  </b-col>
                  <!-- visualiazar -->
                  <b-col cols="1" md="0">
                    <div class="Table-body-action">
                      <img
                        style="cursor: pointer"
                        @click.self="openModalIdLead('visualizar-lead', item)"
                        src="@/assets/img/icons/eye.svg"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </div>
      </div>
      <ModalPipelineAdicionar
        @reload-list="reloadRouteId"
        :tags_props="tagList"
      ></ModalPipelineAdicionar>
      <ModalPipelineEditar
        :selectedPipeline="selectedPipelineItem"
        @update="reloadColumns($event)"
        @prev_selectedPipeline_localStorage="prev_selectedPipeline_localStorage"
        :tags_props="tagList"
        :isIframe="isIframe"
        >
      </ModalPipelineEditar>
      <ModalPipelineTagAdicionar
        ref="ModalPipelineTagAdicionar"
        :selectedPipeline="selectedPipelineItem"
        :getSelectedPipeline="getSelectedPipeline"
        @update="reloadColumns($event)"
        :tags_props="tagList"
      >
      </ModalPipelineTagAdicionar>
      <ModalAdicionarLead
        :selectedPipeline="selectedPipeline"
        :valor_tag_colum="valor_tag_colum"
        :sellers="sellers"
        :changesTags="pipeline_config.add_tag_pipeline"
        @reload-list="fetchPipeline('first')"
        @update="fetchPipeline('update')"
        @atualizar="updateLeadsToView"
        :leadsUnification="leadsUnification"
      ></ModalAdicionarLead>
      <ModalMoverLeadCtrl
        :lead_click="lead_click"
        :ctrl_item="ctrl_item"
        :all_leads="all_leads"
        @reload-list="fetchPipeline('first')"
        @atualizar="updateLeadsToView()"
      >
      </ModalMoverLeadCtrl>
      <ModalMoverLead
        :all_leads="all_leads"
        :filters="configFilter(0,0,0,true)"
        :selectedBord="selectedBord"
        :isSelectedPipeline="selectedPipeline"
        :leadsBoard="leadsBoard"
        @reload-list="fetchPipeline('first')"
        @atualizar="reloadEspecificColumnLead($event)"
      />
      <ModalFiltersPipeline
        @filter="filterPipe"
        :tags_props="tagList"
        :users="users"
        :form_props="forms"
        :userLevelData="userLevelData"
        :recursosData="recursosData"
        :isIframe="isIframe"
      >
      </ModalFiltersPipeline>
      <ModalVisualizarLead
        ref="visualizar-lead"
        @update="updateLeadsToView"
        @updateCRM="updateCRM"
        @returnPostMensage="emitReturnPostMensage"
        :id_lead_selected="id_lead_selected"
        :tags_props="tagList"
        :pipeline_id="selectedPipeline"
        :sellers="sellers"
        :forms="forms"
        :userLevelData="userLevelData"
        :recursosData="recursosData"
        :isIframe="isIframe"
        :userData="userData"
        :leadsUnification="leadsUnification"
      />
      <ModalBusinessPipeline
        :pipeline_id="selectedPipeline"
        :lead_id="selected_lead_action.id"
        :is_pipeline="true"
        @update="lostAction()"
        :reasonType="reasonType"
      />
      <ModalEditarSeller
        :attr_list_seller="attr_list_seller"
        :seller_selected_card="seller_selected_card"
        :sellers="sellers"
        @update="
          reloadEspecificColumn(seller_selected_card, true)
        "
      />
      <ModalMnsgWhats
        :filtros="filters"
        :leadsBoard="leadsBoard"
        :crmID="selectedPipelineItem"
        :search="search"
        @atualizar="updateLeadsToView"
      ></ModalMnsgWhats>
      <ModalMnsgWhatsCtrl
        :whatsCtrl="lead_click.map((lead) => lead.id)"
        @atualizar="updateLeadsToView()"
      ></ModalMnsgWhatsCtrl>
      <editTag
        :dadosTag="dadosTag"
        :origem_dadosTag="origem_dadosTag"
        @add-tag="newTagEdit"
      ></editTag>
    </div>

    <!-- PIPELINE MOBILE -->
    <div class="flex-pipe" v-else>
      <div class="acoesMobile">
        <div class="cont-acoesMobile">
          <div class="flex">
            <div class="acao-home acao-homeMobile">
              <router-link to="/dynamicRoute/home">
                <img src="@/assets/img/icons/home.svg" alt="add-icon" />
              </router-link>
            </div>
            <div class="acoes-inputMobile">
              <div class="flex">
                <img
                  class="acoes-busca"
                  @click="pesquisar_lead = !pesquisar_lead"
                  style="cursor: pointer"
                  src="@/assets/icons/new-search.svg"
                  alt="search-icon"
                />
              </div>
            </div>
          </div>
          <input
            data-anima="top"
            v-if="pesquisar_lead"
            type="text"
            placeholder="Pesquisar lead"
            class="input-buscaMobile"
            @input="debounce"
            v-model="search"
          />
          <div class="acoes-select" v-if="!pesquisar_lead">
            <BaseSelect
              :selectModel="selectedPipelineItem"
              :array="pipelines"
              trackname="title"
              class="select-pipeMobile"
              track-by="id"
              placeholder="Pesquisar"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :allow-empty="false"
              @callback="fetchPipeline(null, null, $event, true)"
              @change="selectedPipelineItem = $event"
              :watch="true"
            >
              <span style="font-size: 13px !important" slot="noResult"
                >Nenhum pipeline encontrado.</span
              >
            </BaseSelect>
          </div>
        </div>
      </div>
      <carousel
        class="baseMobile"
        :style="filterBodyColor2"
        v-if="!loading"
        :perPage="1"
        :centerMode="true"
      >
        <slide
          class="colxMobile"
          v-for="(item, index_column) in getSelectedPipeline"
          :key="item"
          :class="filterTagColor(item)"
        >
          <div class="headerx headerxMobile" :class="filterTagColor(item)">
            <div>
              <h1>
                <!-- {{ filterTag(item) }} -->

                {{
                  filterTag(item).length > 25
                    ? filterTag(item).substr(0, 25) + "..."
                    : filterTag(item)
                }}
              </h1>
              <span class="moneyTotal" v-if="item">{{ moneyTotal(item) }}</span>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 2px;
                align-items: flex-end;
              "
            >
              <div class="numberLeads">
                {{
                  getTotalLeads(item)
                }}
              </div>
            </div>
          </div>
          <div class="list-groupMobile">
            <CardPipeline
                :data-lead="lead.id"
                v-for="(lead, index) in all_leads['lista_' + item].data" 
                :key="`lead_${lead.id}_${index}`" 
                :item="item"
                :data-column-lead="item"
                :index="index"
                :lead="lead"
                :level="level"
                :sellerConfig="sellerConfig"
                :pipeline_config="pipeline_config"
                :lead_click="lead_click"
                :pipelines="pipelines"
                :ctrl_item="ctrl_item"
                :tag="tag"
                :selectedPipeline="selectedPipeline"
                :sellers="sellers"
                :user="user"
                :totalPagina="totalPagina"
                :configSellerCanTransferLead="configSellerCanTransferLead"
                :currentChecks="currentChecks"
                :isIframe="isIframe"
                @returnPostMensage="emitReturnPostMensage"
                @update:leadsBoard="leadsBoard = $event"
                @update:seller_selected_card="seller_selected_card = $event"
                @update:valor_tag_colum="valor_tag_colum = $event"
                @update:totalPagina="totalPagina = $event"
                @update:configSellerCanTransferLead="configSellerCanTransferLead = $event"
                @update:id_lead_selected="id_lead_selected = $event"
                @update:reasonType="reasonType = $event"
                @update:selected_lead_action="selected_lead_action = $event"
                @reload-especific-column="getReloadEspecificColumn"
                v-observe-visibility="index === all_leads['lista_' + item].data.length - 7 ? onVisibilityChange : false"
              >
            </CardPipeline>
          </div>
        </slide>
      </carousel>
      <div class="Spinning" v-else>
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>
<script>
import ModalPipelineAdicionar from "@/components/Pipeline/ModalPipelineAdicionar";
import ModalPipelineTagAdicionar from "@/components/Pipeline/ModalPipelineTagAdicionar";
import ModalMoverLead from "@/components/Pipeline/ModalMoverLead";
import ModalMoverLeadCtrl from "@/components/Pipeline/ModalMoverLeadCtrl";
import ModalPipelineEditar from "@/components/Pipeline/ModalPipelineEditar";
import ModalAdicionarLead from "@/components/Pipeline/ModalAdicionarLead";
import ModalFiltersPipeline from "@/components/Pipeline/ModalFiltersPipeline";
import ModalVisualizarLead from "@/components/Pipeline/ModalVisualizarLead";
import CardPipeline from "@/components/Pipeline/CardPipeline";
import ModalEditarSeller from "@/components/Pipeline/ModalEditarSeller";
import ModalMnsgWhats from "@/components/Pipeline/ModalMnsgWhats";
import ModalMnsgWhatsCtrl from "@/components/Pipeline/ModalMnsgWhatsCtrl";
import ModalProducts from "@/components/Pipeline/ModalProducts";
import Multiselect from "vue-multiselect";
import BaseButton from "@/components/BaseButton";
import draggable from "vuedraggable";
import moment from "moment-timezone";

import LeadListaService from "@/services/resources/LeadListaService";
const serviceListaLeads = LeadListaService.build();

import LeadValuePipelineService from "@/services/resources/LeadValuePipelineService";
const serviceValueLeads = LeadValuePipelineService.build();

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import LeadService from "@/services/resources/LeadService";
const serviceLead = LeadService.build();

import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTags = ListarTagService.build();

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
const serviceProduct = ConfigProdutosService.build();

import _ from "lodash";

import DateRangePicker from "vue2-daterange-picker";

import Vue from "vue";

import { Carousel, Slide } from "vue-carousel";

import UserLoggedService from "@/services/resources/UserLoggedService";
import { datetime } from "../../filters/date";
const serviceMe = UserLoggedService.build();

import ActivitieService from "@/services/resources/ActivitieService";
const serviceActivitie = ActivitieService.build();

import ModalBusiness from "@/components/LeadUnico/ModalBusiness.vue";
import ModalBusinessPipeline from "@/components/LeadUnico/ModalBusinessPipeline.vue";

import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";

import UsuariosService from "@/services/resources/UsuariosService";
const serviceUsuarios = UsuariosService.build();

import editTag from "@/components/Tags/AddTag";

export default {
  props: ['userLevelData', 'recursosData', 'userData', 'usersData', 'tagsData', 'productsData', ],
  components: {
    draggable,
    BaseButton,
    ModalPipelineAdicionar,
    ModalPipelineTagAdicionar,
    ModalPipelineEditar,
    CardPipeline,
    ModalAdicionarLead,
    ModalFiltersPipeline,
    DateRangePicker,
    ModalVisualizarLead,
    ModalMoverLead,
    ModalEditarSeller,
    ModalMoverLeadCtrl,
    ModalMnsgWhats,
    ModalMnsgWhatsCtrl,
    ModalProducts,
    Multiselect,
    Carousel,
    Slide,
    ModalBusiness,
    editTag,
    ModalBusinessPipeline,
  },
  data() {
    return {
      colors: false,
      showMoreActions: false,
      add_tag_pipeline:'false',
      currentLabel: ["Apple", "Mango", "Orange", "Watermelon"],
      currentValue: [20, 50, 80, 40],
      currentChecks: [],
      products: [],
      recursos:{},
      detailProduct: -1,
      loading_fetching: false,
      time_get: null,
      totalPagina: 15,
      changeCompanylead: false,
      origem_dadosTag: null,
      dadosTag: {},
      allMetaphotoUser: [],
      attr_list_seller: "",
      bodyColor1: "default",
      bodyColor2: "default",
      total: 0,
      selected_lead_action: {},
      selected_lead: [],
      stop: false,
      list_type: true,
      configSellerCanTransferLead: "",
      level: 'admin',
      me: {},
      prev_selectedPipeline: null,
      sellerConfig: "",
      selecionarPipeline: "",
      lead_click: [],
      pipeline_config: {},
      leadsBoard: null,
      loading: true,
      orderby:'order',
      render: true,
      pipelines: [],
      selectedPipeline: null,
      selectedPipelineItem: null,
      all_leads: null,
      tag: null,
      tags: [],
      valor_tag_colum: 0,
      sellerSelected: [],
      forms: [],
      search: null,
      filters: {},
      tagList: [],
      users: [],
      id_lead_selected: [],
      seller_selected_card: [],
      pesquisar_lead: false,
      ctrl_item: [],
      client: {
        width: 0,
      },
      loading_columns: [],
      loadingColumnsLeads: [],
      user: {},
      sellers: [],
      show_origin_form: false,
      selectedBord: '',
      reasonType: 'loss',
      loadingNamesBoard: false,
      leadsUnification: false
    };
  },
  watch: {
    search(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.updateLeadsToView();
        }, 700);
      }
    },
    changeCompanylead(e) {
      if (!e) {
        this.filters.company_view = false;
      } else {
        this.filters.company_view = true;
      }
      this.updateLeadsToView();
    },
    $route() {
      this.search = null;
      this.pesquisar_lead = false;
      if (this.$route.params.id) {
        this.initPipe();
      }
    },
    list_type() {
      if (!this.list_type) {
        setTimeout(() => {
          var tables = document.querySelectorAll(".Table-body");
          for (var i = 0; i < tables.length; i++) {
            if (i % 2 === 0) {
              tables[i].style.background = "#FFF";
            }
          }
        }, 0);
      }
    },
    selectedPipelineItem(event) {
      if (
        event &&
        this.pipelines.length &&
        "created_at" in event &&
        "id" in event &&
        "status" in event &&
        "tag_exit" in event &&
        "tags" in event &&
        "title" in event &&
        "updated_at" in event
      ) {
        var new_event = event;
        var index = this.pipelines.indexOf(event);
        var id_pipeline = this.pipelines[index].id;
      }

      // Verifica se o objeto contém apenas a propriedade 'id'
      if (event && "id" in event && Object.keys(event).length === 1) {
        var get_index = this.pipelines.findIndex((pipe) => pipe.id == event.id);
        var new_event = this.pipelines[get_index];
        var id_pipeline = new_event.id;
      }

      if (id_pipeline && event) {
        this.prev_selectedPipeline = this.selectedPipeline;
        this.selectedPipeline = event.id;
        localStorage.setItem("selectedPipeline", event.id);
        localStorage.setItem(
          "prev_selectedPipeline",
          this.prev_selectedPipeline
        );
        var array_base = [];
        var tags_e = new_event.tags;
        for (let i = 0; i < tags_e.length; i++) {
          const element = tags_e[i];
          array_base.push({ id: element, loading: false });
        }
        this.loading_columns = array_base;
        if (!this.$route.params.id || this.$route.params.id != id_pipeline) {
          if(this.isIframe) {
            this.$router.push(`${id_pipeline}`)
          } else {
            this.$router.replace({
              name: "CRM_id",
              params: {
                dynamicRoute: this.user.tenant_subdomain,
                id: id_pipeline,
              },
            });
          }
        }
      } else {
        var prev = localStorage.getItem("prev_selectedPipeline");
        var index2 = this.pipelines.find((x) => x.id == prev);
        var index3 = this.pipelines.indexOf(index2);
        var prev_pipelines = this.pipelines[index3];
        if (prev_pipelines && prev_pipelines.id) {
          localStorage.setItem("selectedPipeline", prev_pipelines.id);
          this.selectedPipeline = prev_pipelines.id;
          var array_base = [];
          var tags_e = prev_pipelines.tags;
          for (let i = 0; i < tags_e.length; i++) {
            const element = tags_e[i];
            array_base.push({ id: element, loading: false });
          }
          this.loading_columns = array_base;
        } else if (this.pipelines && this.pipelines.length) {
          localStorage.setItem("selectedPipeline", this.pipelines[0].id);
          this.selectedPipelineItem = this.pipelines[0].id;
          var array_base = [];
          var tags_e = this.pipelines[0].tags;
          for (let i = 0; i < tags_e.length; i++) {
            const element = tags_e[i];
            array_base.push({ id: element, loading: false });
          }
          this.loading_columns = array_base;
        }
      }
    },
    async selectedPipeline(event) {
      await this.setAllColuns(event);
    },
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    isIframe() {
      return window.self !== window.top
    },
    missingHeight() {
      return this.getSelectedPipeline.map((columnId, index) => columnId = `height: calc(${(this.all_leads['lista_' + columnId].total - this.all_leads['lista_' + columnId].data.length) * 220}px - ${this.showLoadingCards[index] ? 15 * 220 : 0}px); width: 100%;`)
    },
    showLoadingCards() {
      return this.getSelectedPipeline.map(columnId => columnId = this.loadingColumnsLeads.includes(columnId))
    },
    filterBodyColor2() {
      const colors = {
        default: "var(--gray01)",
        success: "#4b2cbd",
        primary: "var(--greenn-light)",
        warning: "var(--orange-light)",
        danger: "var(--red)",
        info: "var(--bluee)",
      };
      if (this.bodyColor1 && this.bodyColor2) {
        const color1 = colors[this.bodyColor1] || colors.default;
        const color2 = colors[this.bodyColor2] || colors.default;
        return `background: linear-gradient(to right, ${color1} 50%, ${color2} 50%)`;
      }
    },
    isMobile() {
      return this.client.width <= 768;
    },
    getSelectedPipeline() {
      if (this.selectedPipeline) {
        return this.pipelines.find((x) => x.id == this.selectedPipeline).tags;
      } else {
        return [];
      }
    },
    foto_usuario() {
      var metas = this.me && this.me.metas ? this.me.metas : [];
      for (let i = 0; i < metas.length; i++) {
        const element = metas[i];
      }

      for (let i = 0; i < metas.length; i++) {
        const element = metas[i];

        if (element.meta_key === "photo") {
          if (element.meta_key == "photo") {
            if (element.meta_value === "") {
              return false;
            } else if (typeof element.meta_value === "string") {
              return element.meta_value;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    },
  },
  methods: {
    async setLeadsUnification(){
      this.$store.dispatch('getUniqueFlag', 'leads_unification')
      .then(async (resp) => {
        this.leadsUnification = resp;
      });
    },
    selectIdLeadAndOpenLeadModal(idLead) {
      this.id_lead_selected = idLead;
      this.$grModal.show('visualizar-lead', this);
    },
    updateDataIframeCrm(dataKey, newValue) {
      const mapping = {
        tagsData: 'tagList',
        recursosData: 'recursos',
        userData: 'user',
        userLevelData: 'level',
        productsData: 'products'
      };
      if (mapping[dataKey]) {
        this[mapping[dataKey]] = newValue;
      }
    },
    openModalMove(bord){
      this.selectedBord = bord
      this.openModal("MoverLead")
    },
    openLeadWpp(phone){
      let data ={
        type: 'openLead',
        href:this.formatTel(phone)
      }
      this.$emit('returnPostMensage', data)
    },
    emitReturnPostMensage(data){
      this.$emit('returnPostMensage', data)
    },
    getTotalLeads(item){
      if(this.all_leads && this.all_leads["lista_" + item] && this.all_leads["lista_" + item].total){
        return this.all_leads["lista_" + item].total;
      }
      return 0;
    },
    updateCRM(data) {
      if (this.selectedPipeline != data.pipeline || !this.all_leads) {
        return
      }
      if (data.lastBoard) { 
        const boardLine = this.all_leads["lista_" + data.lastBoard];
        const index = boardLine.data.findIndex(x => x.id === data.lead.id);
        if (index !== -1) {
          boardLine.data.splice(index, 1);
          boardLine.total = boardLine.total ? boardLine.total - 1 : 0;
        }
      }
      if (data.board) {
        const board = this.all_leads["lista_" + data.board];
        if (board) {
          const index = board.data.findIndex(x => x.id === data.board);
          if (index === -1 && !board.data.find(x => x.id === data.lead.id)) {
            board.data.push(data.lead);
            board.total = board.total ? board.total + 1 : 1;
          }
        }
      }
    },
    setColors() {
      localStorage.setItem("crmColors", JSON.stringify(this.colors));
    },
    showOrignForm(){
      serviceSettings
        .read('meta/show_origin_form')
        .then((resp) => {
          if(resp && resp.meta_value && resp.meta_value == 'true'){
            this.show_origin_form = true;
          }
        })
    },
    showProductDetail(data) {
      let total = 0;
      let list_id = [];
      let list_value = [];
      for (let i = 0; i < this.all_leads["lista_" + data].data.length; i++) {
        const element = this.all_leads["lista_" + data].data[i];
        for (let x = 0; x < element.products.length; x++) {
          const product = element.products[x];
          if (list_id.includes(product.product_id)) {
            const index = list_id.indexOf(product.product_id);
            list_value[index] += parseFloat(product.net_value);
          } else {
            list_id.push(product.product_id);
            list_value.push(parseFloat(product.net_value));
          }
        }
      }
      this.currentLabel = list_id.map(
        (item) =>
          (item = this.products.find((product) => product.id == item).name)
      );
      this.currentValue = list_value;
    },
    getProduct(page = 1) {
      if (this.isIframe) {
        return
      }
      var data = {
        page: page,
        id: `list?page${page}`,
        per_page: 1000,
      };
      serviceProduct
        .createId(data)
        .then((resp) => {
          this.products = resp.data;
        })
        .catch((err) => {});
    },
    saveDate() {
      const dataAtual = new Date();
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const dataFormatada = dataAtual.toLocaleDateString("pt-BR", options);

      this.time_get = dataFormatada;
    },
    openLead(id) {
      const url = `${process.env.BASE_URL}${this.user.tenant_subdomain}/lead/${id}`;
      window.open(url, "_blank");
    },
    async reloadRouteId() {
      await servicePipeline.search({ page: 1, per_page: 100 }).then((resp) => {
        this.pipelines = resp.data;
        var local = localStorage.getItem("selectedPipeline");
        if (this.isIframe) {
          this.$router.push(`${local}`);
        } else {
          this.$router.replace({
            name: "CRM_id",
            params: {
              dynamicRoute: this.user.tenant_subdomain,
              id: local,
            },
          });
        }
        var encontrado = this.pipelines.find((item) => item.id == local);
        this.selectedPipelineItem = encontrado;

      });
    },
    async onVisibilityChange(isVisible, entry) {
      if (isVisible) {
        let columnId = parseInt(entry.target.dataset.columnLead);
        let current_page = parseInt(this.all_leads['lista_' + columnId].current_page);
        let last_page = parseInt(this.all_leads['lista_' + columnId].last_page);
        if (current_page === last_page || this.loadingColumnsLeads.includes(`${columnId}`)) {
          return;
        }
        if (current_page < last_page) {
          current_page++;
        }
        let data = this.configFilter(columnId, this.search, current_page);
        let copy_all_leads = JSON.parse(
          JSON.stringify(this.all_leads["lista_" + columnId])
          );
          let all_leads_item = JSON.parse(
            JSON.stringify(this.all_leads["lista_" + columnId].data)
            );
        let all_leads_response = [];

        this.loadingColumnsLeads.push(`${columnId}`)
        await serviceListaLeads
          .create(data)
          .then(async (response) => { 
            all_leads_response = response.data;
            var array_leads_final = all_leads_item.concat(all_leads_response);
            copy_all_leads = response;
            copy_all_leads.data = array_leads_final;
            const value_total = await this.onValueTotal(data); 
            copy_all_leads.valor_total = value_total;
            Vue.set(this.all_leads, "lista_" + columnId, copy_all_leads);
          })
          .catch((error) => {})
          .finally(() => {
            let loadingIndex = this.loadingColumnsLeads.indexOf(`${columnId}`)
            this.loadingColumnsLeads.splice(loadingIndex, 1)
            if (this.lead_click) {
              var copy_lead_clicks = JSON.parse(
                JSON.stringify(this.lead_click)
              );
              for (let x = 0; x < copy_lead_clicks.length; x++) {
                const leads = copy_lead_clicks[x];
                var div_lead = document.querySelector(
                  `div[data-lead="${leads.id}"]`
                );
                div_lead.classList.add("selected");
              }
            }
          });
      }
    },
      async onValueTotal(data){
      try {
        const value = await serviceValueLeads
          .create(data);
          return value.total_net_value;
      } catch (error) {
        
      }
    },
    newTagEdit(newTag) {
      this.loading = true;
      var index_tag = this.tagList.findIndex(
        (tagFind) => tagFind.id == newTag.id
      );
      this.tagList[index_tag] = newTag;
      this.loading = false;
      this.dadosTag = {};
      this.origem_dadosTag = null;
    },
    editTag(data, tag) {
      this.$bvModal.show(data);
      var findTag = this.tagList.find((tagFind) => tagFind.id == tag);
      this.dadosTag = findTag;
      this.origem_dadosTag = true;
    },
    focusInputSearch() {
      this.pesquisar_lead = true;
      this.$nextTick(() => this.$refs.focusInput.focus());
    },
    async reloadColumns(result_tags) {
      var indexPipe = this.pipelines.findIndex(
        (pipeFind) => pipeFind.id == this.selectedPipeline
      );

      if ((indexPipe && indexPipe != -1) || indexPipe === 0) {
        this.pipelines[indexPipe].tags = result_tags;
        this.loading_columns = [];
        var loading_columns = [];
        for (let i = 0; i < result_tags.length; i++) {
          const tag = result_tags[i];
          loading_columns.push({ id: tag, loading: false });
        }
        this.loading_columns = loading_columns;
        await this.setAllColuns(this.selectedPipeline);
      }
    },
    openAddTag() {
      this.openModal("ModalPipelineTagAdicionar");
    },
    moneyTotal(data) {
      let total = 0;
      if (
        this.all_leads &&
        this.all_leads["lista_" + data] &&
        this.all_leads["lista_" + data].valor_total &&
        this.all_leads["lista_" + data].data &&
        this.all_leads["lista_" + data].data.length
      ) {
        for (let i = 0; i < this.all_leads["lista_" + data].data.length; i++) {
          const element = this.all_leads["lista_" + data];
          total = element.valor_total;
        }
        const formatMoney = total.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return formatMoney;
      }
      return "R$ 0,00";
    },
    // filterForm(forms) {
    //   // let store_forms = this.forms;
    //   // var index = forms.length - 1;
    //   // if (index > -1) {
    //   //   let result = store_forms.find((x) => x.id == forms[index].form_id);
    //   //   if (result) {
    //   //     result.assoc_date = forms[index].updated_at;
    //   //     return result;
    //   //   }
    //   //   return { title: "Desconhecido", created_at: "" };
    //   // }
    //   // return { title: "Desconhecido", created_at: "" };
    // },
    filterForm(forms, first_form) {
      var index = 0;
      if(!first_form && forms){
        index = forms.length - 1;
      }
        
      if (index > -1 && forms) {
        var result = forms[index];
        if (result) {
          return result;
        }
        return { title: "Desconhecido", created_at: "" };
      }
      return { title: "Desconhecido", created_at: "" };
    },
    filterPage(views) {
      if (views.length) {
        return views[0].title;
      }
      return "Desconhecido";
    },
    filterTagTable(tags_assoc) {
      let tags = this.tags;
      var index = tags_assoc.length - 1;
      if (index > -1) {
        return tags_assoc[index]
          ? tags_assoc[index]
          : { name: "Sem tag", color: "" };
      }
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    filterLeadscore(ls) {
      if (!ls) {
        return "#cecece";
      } else if (ls <= 0) {
        return `#43ff64d9`;
      } else if (ls <= 10) {
        return `#3FA9DE`;
      } else if (ls <= 20) {
        return `#3DA1DF`;
      } else if (ls <= 30) {
        return `#9ABD5C`;
      } else if (ls <= 40) {
        return `#37A0A4`;
      } else if (ls <= 50) {
        return `#A373A8`;
      } else if (ls <= 60) {
        return `#B19A86`;
      } else if (ls <= 70) {
        return `#EFC033`;
      } else if (ls <= 80) {
        return `#E2845B`;
      } else if (ls <= 90) {
        return `#D82984`;
      } else if (ls <= 100) {
        return `#D34F68`;
      } else if (ls <= 120) {
        return `#008E7B`;
      } else if (ls > 120) {
        return `var(--greenn)`;
      } else {
        return "#cecece";
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "is-light-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "is-light-blue" };
            break;
          }
          case "active": {
            r = {
              text: "Pago",
              class: "is-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "is-warning" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "is-light-blue" };
            break;
          }

          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    getMe() {
      serviceMe.search().then((r) => {
        this.me = r.user;
      });
    },
    prev_selectedPipeline_localStorage() {
      localStorage.removeItem("prev_selectedPipeline");
      localStorage.removeItem("selectedPipeline");
      location.reload();
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    stopLoadingColum() {
      var itens = this.loading_columns;
      for (let i = 0; i < itens.length; i++) {
        Vue.set(this.loading_columns[i], "loading", false);
      }
    },
    removerLeadsCtrl(element) {
      var element_primeiro = element;
      Vue.swal({
        title: "Pipeline",
        text: `Deseja remover todos os leads selecionados?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var leads = element;
          var coluns = this.loading_columns;
          for (let i = 0; i < leads.length; i++) {
            const element = leads[i];
            var index = coluns.findIndex((x) => x.id === element.colum_id);
            Vue.set(this.loading_columns[index], "loading", true);
          }
          for (let i = 0; i < element.length; i++) {
            const id = element[i].id;
            for (let x = 0; x < this.ctrl_item.length; x++) {
              const id_colum = this.ctrl_item[x];
              var all = this.all_leads["lista_" + id_colum].data;
              if (all.indexOf(element[i]) !== -1) {
                var index = all.indexOf(element[i]);
                var data_loop = {
                  id: `lead/desassoc/`,
                  lead_id: id,
                  pipeline_id: this.selectedPipeline,
                };
                servicePipeline
                  .createId(data_loop)
                  .then(() => {
                    if (i === 1) {
                      this.$grToast.toast(
                        `Todos os leads selecionados foram removidos.`,
                        {
                          title: "CRM",
                          variant: "info",
                          autoHideDelay: 5000,
                          appendToast: true,
                        }
                      );
                    }
                    this.reloadEspecificColumn(element_primeiro);
                  })
                  .catch(() => {});
              }
              // APENAS TESTE APAGAR DEPOIS
              for (let u = 0; u < element.length; u++) {
                const divs = element[u].id;
                var divAll = document.querySelector(`[data-lead="${divs}"]`);
                divAll.classList.add("sumiu");
              }
            }
          }
          // this.updateLeadsToView();
        }
      });
      this.lead_click = [];
    },
    async reloadEspecificColumnLead(callback) {
      this.lead_click = [];

      if (callback) {
        var coluns = this.loading_columns;
        //atualiza duas colunas se nao apenas uma
        if (coluns.find((x) => x.id == callback)) {
          await this.reloadEspecificColumn({ colum_id: this.selectedBord }, true);
          await this.reloadEspecificColumn({ colum_id: callback + "" }, true);
        }
      }
    },
    async reloadEspecificColumn(element, update) {
      var coluns_and_leads = Array.isArray(element) ? element : [element];

      if (update) {
        var coluns = this.loading_columns;
        for (let i = 0; i < coluns_and_leads.length; i++) {
          const element = coluns_and_leads[i];
          var index = coluns.findIndex((x) => x.id == element.colum_id);
          Vue.set(this.loading_columns[index], "loading", true);
        }
      }

      for (let i = 0; i < coluns_and_leads.length; i++) {
        const colum_id = coluns_and_leads[i].colum_id;

        var final = await this.fetchLeads([colum_id], null, true, 1);

        this.all_leads["lista_" + colum_id] = final;
      }
      setTimeout(() => {
        this.stopLoadingColum();
      }, 1000);
    },
    removerLeadsBoard(item) {
      var tags = this.tagList;
      var tagName = tags.find((x) => x.id == item).name ?? "default";
      let leadsBoard = this.all_leads["lista_" + item].data;
      Vue.swal({
        title: "CRM",
        text: `Deseja remover todos os leads do board ${tagName}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (let i = 0; i < leadsBoard.length; i++) {
            const element = leadsBoard[i];
            var data_loop = {
              id: `lead/desassoc/`,
              lead_id: element.id,
              pipeline_id: this.selectedPipeline,
            };
            servicePipeline
              .createId(data_loop)
              .then(() => {
                if (i === 0) {
                  this.$grToast.toast(
                    `Todos os leads do board ${tagName} foram removidos`,
                    {
                      title: "CRM",
                      variant: "info",
                      autoHideDelay: 5000,
                      appendToast: true,
                    }
                  );
                  this.reloadEspecificColumn({ colum_id: item }, true);
                }
              })
              .catch(() => {
                // this.$grToast.toast("Erro ao Alterar Vendedor", {
                //   title: "CRM",
                //   variant: "danger",
                //   autoHideDelay: 5000,
                //   appendToast: true,
                // });
              });
          }
        }
      });
    },
    formatMoney(products, decPlaces, decSep, thouSep = ".") {
      if (products.length === 0) {
        return "0,00";
      }
      var total = 0;
      products.forEach(function (value) {
        total += value.net_value;
      });
      var number = total;
      number = parseFloat(number);
      if (typeof number !== "undefined" && number)
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
    },
    async buscarTagsApi() {
      if (this.isIframe) {return}
      
      this.loadingNamesBoard = true
      const cachedData = await getCachedData("tags");

      if (cachedData) {
        this.tagList = cachedData;
      }

      await serviceListarTags.search({ per_page: 1000 }).then((resp) => {
        cacheData("tags", resp.data);
        this.tagList = resp.data;
      }).finally(() => {
        this.loadingNamesBoard = false;
      })
    },
    debounce: _.debounce(function (search) {
      this.fetchPipeline("search", search);
    }, 500),
    async filterPipe(e) {
      this.filters = e;
      e.campo_data ?  this.orderby = e.campo_data : 'order'; 
      await this.fetchPipeline("seller");
    },
    filterSelectedSeller(seller) {
      this.filters.vendedor = [];
      if (this.sellerSelected === seller.id && this.level != "seller") {
        this.sellerSelected = [];
        this.fetchPipeline("seller");
        return;
      } else {
        if (this.sellerSelected === seller.id && this.level == "seller") {
          return;
        }

        this.sellerSelected = seller.id;
        this.filters.vendedor = [this.sellerSelected];
        this.fetchPipeline("seller");
      }
    },
    getMetaEmail(user) {
      return user.email;
    },
    getMetaInitial(user) {
      if (user) {
        var name = user?.first_name || "";
        var sobrenome = user?.last_name || "";
      }
      if (!name && !sobrenome) {
        return user.email.substring(0, 2);
      }
      if (!name) {
        return sobrenome.substring(0, 2);
      }
      if (!sobrenome) {
        return name.substring(0, 2);
      }
      return name[0] + sobrenome[0];
    },
    getMetaName(user) {
      var name = user.first_name;
      if (!name) {
        return user.email.substring(0, 7) + "..";
      }
      return name.substring(0, 7) + "..";
    },
    getMetaPicture(metas, item) {
      if(!metas){
        return;
      }
      for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        if (meta.meta_key == "photo") {
          if (meta.meta_value === "") {
            return false;
          } else if (typeof meta.meta_value === "string") {
            return this.changeUrl(meta.meta_value);
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    getReloadEspecificColumn (column) {
      this.reloadEspecificColumn({ colum_id: column }, true);
    },
   async changeLead(event) {
      if (this.loading_fetching) {
        this.$grToast.toast("Aguarde enquanto termina de carregar!", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.loading = true;
      this.loading = false;
      let id_lead = event.item.dataset.lead;
      let id_tag_colum = event.to.dataset.id;
      let delete_tag_colum = event.from.dataset.id;

      //deleta o lead
      if (event.to._prevClass == "deletar") {
        let data3 = {
          id: `${id_lead}/pipeline/${this.selectedPipeline}/${delete_tag_colum}`,
        };

        serviceLead.destroy(data3);

        this.$grToast.toast("Lead removido", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        // Diminui o total da coluna que saiu o lead -->
        var total_delete =
          this.all_leads["lista_" + delete_tag_colum].total - 1;
          Vue.set(this.all_leads, "lista_" + delete_tag_colum, {
          ...this.all_leads["lista_" + delete_tag_colum],
          total: total_delete,
        });
        return;
      }

      //pega os leads que estao na nova coluna
      setTimeout(() => {
        var id_coluna_anterior = event.to.dataset.id;
        var leads = document.querySelector(
          `[data-id="${id_coluna_anterior}"]`
        ).childNodes;
        var todos_leads = [];
        for (let i = 0; i < leads.length; i++) {
          const lead = leads[i];
          todos_leads.push({ lead_id: lead.dataset.lead });
        }
        var data2 = {
          id: "order",
          order: todos_leads,
        };
        serviceLead.createId(data2);
      }, 1000);
      // Associa a tag do board ao lead
      if (
        this.pipeline_config &&
        this.pipeline_config.add_tag_pipeline &&
        this.pipeline_config.add_tag_pipeline === "true" &&
        id_tag_colum != delete_tag_colum
      ) {
        let data = {
          id: "/assoc",
          leads: [id_lead],
          tag_id: id_tag_colum,
        };
        serviceTag
          .createId(data)
          .then(() => {
              if(this.add_tag_pipeline === 'true'){
                let board = this.all_leads["lista_" + id_tag_colum];
                if(board){
                  let all_leads = board.data;
                  let index = all_leads.findIndex((x) => x.id == id_lead);
                  let tags = board.data[index].tags;
                  if(tags && !tags.find((x) => x.id == id_tag_colum) && index !== -1){
                      let tag = this.tagList.find((x) => x.id == id_tag_colum);
                      tags.push(tag);
                  }
                }
              }
          })
          .catch(() => {
            this.$grToast.toast("Erro ao adicionar tag", {
              title: "Lead",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      }
      // Diminui o total da coluna que saiu o lead -->
      var total_delete = this.all_leads["lista_" + delete_tag_colum].total - 1;
      Vue.set(this.all_leads, "lista_" + delete_tag_colum, {
        ...this.all_leads["lista_" + delete_tag_colum],
        total: total_delete,
      });

      // Aumenta o total da coluna que entrou o lead -->
      var total_entry = this.all_leads["lista_" + id_tag_colum].total + 1;
      Vue.set(this.all_leads, "lista_" + id_tag_colum, {
        ...this.all_leads["lista_" + id_tag_colum],
        total: total_entry,
      });

      //atualiza quando move o lead
      var data = {
        id: `${id_lead}/${this.selectedPipeline}/${id_tag_colum}`,
      };
      servicePipeline.update(data).then(() => {
        this.currentChecks.push(id_lead)
        setTimeout(() => {
          let checkIndex = this.currentChecks.indexOf(id_lead)
          this.currentChecks.splice(checkIndex, 1)
        }, 1000);
      });
    
      await new Promise(resolve => setTimeout(resolve, 500));
      var filterDelete = await this.configFilter(delete_tag_colum, null, null);
      const value_total_delete = await this.onValueTotal(filterDelete); 
      this.all_leads["lista_" + delete_tag_colum].valor_total = value_total_delete;

      var filter = await this.configFilter(id_tag_colum, null, null);
      const value_total = await this.onValueTotal(filter); 
      this.all_leads["lista_" + id_tag_colum].valor_total = value_total;
    },
    configFilter(id, search, page ,returnFilters ) {
      let data = {
        filters: {
          status: "active",
          search: this.search,
          board: [id],
          campanha: [],
          company: [],
          company_view: this.changeCompanylead,
          tag: this.filters.tag,
          tag_exceto: [],
          email: [],
          status_email: "",
          formulario: [],
          vendedor:
            this.sellerSelected.length === 0 ? [] : [this.sellerSelected],
          funil: [],
          leadscore_minimo: "",
          leadscore_maximo: "",
          status_jornada: [],
          campos_adicionais: [],
          data_inicio: "",
          data_fim: "",
          acao_email_tempo: "",
          higiene: "",
          campo_data: "register",
          data_inicial: null,
          data_final: null,
          pipeline: [this.selectedPipeline],
          vl_liquido: "",
        },
        leads: [],
        order: "ASC",
        orderby: this.orderby,
        page: page ? page : 1,
        pagina: page ? page : 1,
        total_pagina: this.totalPagina,
        withs: [
          "Tags",
          "Products",
          "Forms",
          "Photo",
          "Situations",
          "Companies",
          "Views",
        ],
      };
      if(returnFilters){
        return data.filters
      }
      if (search != null && typeof search == "string" && search.length > 0) {
        data.filters.search = search;
      }

      data.filters.data_inicial = moment
        .tz("2012-02-01", "America/Sao_Paulo")
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      data.filters.data_final = moment
        .tz(moment(), "America/Sao_Paulo")
        .format("YYYY-MM-DD");

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data.filters = Object.assign(data.filters, this.filters);
      return data;
    },
    async fetchLeads(tags, search = null, especific, page) {
      return new Promise(async (resolve) => {
        var final = {};
        var final2 = [];
        var promises = []; // Array para armazenar as Promises das chamadas assíncronas

        for (let i = 0; i < tags.length; i++) {
          const tag = tags[i];
          var data = this.configFilter(tag, search, page);
          if (data.filters.company.length) {
            data.filters.company_view = false;
          }
          this.filters && this.filters.campo_data ? this.filters.campo_data = this.orderby : null;
          // Armazene a Promise da chamada assín  crona em 'promises'
          promises.push(
            serviceListaLeads.create(data).then(async (response) => {
              for (let i = 0; i < response.data.length; i++) {
                const element = response.data[i];
                if(element.follow_up){
                  var agora = moment();
                  var data_follow_up = moment(element.follow_up);
                  if (moment(agora).isSame(data_follow_up, "day")) {
                    element.followUpClass = 'color-alert';
                  } else if (agora > data_follow_up) {
                    element.followUpClass = 'color-red';
                  } else {
                    element.followUpClass = 'color-greenn';
                  }
                }
              }
              if (this.changeCompanylead) {
                var newResponse = response;
                for (let i = 0; i < response.data.length; i++) {
                  const element = response.data[i];
                  if (
                    newResponse &&
                    newResponse.data[i] &&
                    element &&
                    element.companies[0]
                  ) {
                    newResponse.data[i].name = element.companies[0].name;
                  }
                }
                var result = {};
                if (especific) {
                  result = newResponse;
                  Object.assign(final2, result);
                } else {
                  result["lista_" + tag] = newResponse;
                  Object.assign(final, result);
                }
              } else {
                var result = {};
                if (especific) {
                  result = response;
                  Object.assign(final2, result);
                } else {
                  result["lista_" + tag] = response;
                  var filter =  this.configFilter(tag, search, page);
                  const value_total = await this.onValueTotal(filter); 
                  result["lista_" + tag].valor_total = value_total;
                  Object.assign(final, result);
                }
              }
            })
          );
        }
        // Aguarde a conclusão de todas as Promises antes de resolver a Promise externa
        await Promise.all(promises);

        if (especific) {
          resolve(final2);
        } else {
          resolve(final);
        }
      });
    },
    async fetchConfigPipeline() {
      const cachedData = await getCachedData("fetchConfigPipeline");
      var data = {
        id: "pipeline",
      };
      serviceSettings.read(data).then((resp) => {
        cacheData("fetchConfigPipeline", resp);
        const mapping = {
          phone_pipeline: "phone_pipeline",
          tag_lead_pipeline: "tag_lead_pipeline",
          follow_lead: "follow_lead",
          value_lead: "value_lead",
          add_tag_pipeline: "add_tag_pipeline",
          companies_value: "companies_value"
        };
        var result = {};
        const metas = resp;
        if (!metas ||!metas.length) {
          result = {
            follow_lead: true,
            tag_lead_pipeline: true,
            phone_pipeline: true,
            value_lead: true,
            add_tag_pipeline: "true",
            companies_value: true
          };

          
          this.pipeline_config = result;
          return;
        }

        for (const meta of metas) {
          const prop = mapping[meta.meta_key];
          if (prop !== undefined) {
            result[prop] = meta.meta_value;
          }
        }
        this.pipeline_config = result;
      });
    },
    fetchUsers() {
      return new Promise((resolve, reject) => {
        if (this.isIframe) {
          this.users = this.usersData;
          this.sellers = this.usersData;
          this.sellersnew = this.usersData;
          return
        }
        serviceUsuarios
          .read("users?per_page=1000")
          .then((response) => {
            this.users = response.data;
            this.sellers = response.data;
            this.sellersnew = response.data;
            resolve(true);
          })
          .catch((erorr) => {
            reject(erorr);
          })
          .finally(() => {});
      });
    },
    lostAction() {
      this.reloadEspecificColumn({ colum_id: this.leadsBoard }, true);
    },
    filterTag(id) {
      if (this.loadingNamesBoard) return "Carregando...";

      const tag = this.tagList.find((x) => x.id == id);
      return tag ? tag.name : "excluída";
    },
    filterTagColor(id) {
      var tags = this.tagList;
      if (tags && tags.length) {
        var color1 = tags[0].color;
        const color2 = tags[tags.length - 1].color;
        this.bodyColor1 = color1;
        this.bodyColor2 = color2;
        return tags.find((x) => x.id == id)?.color ?? "default";
      }
      return "default";
    },
    changeUrl(url) {
      var num = Math.random();
      return url + "?v=" + num;
    },
    filterSeller(id) {
      var sellers = this.sellers;
      var find_id = sellers.find((x) => x.id == id);
      var name = find_id && find_id.first_name ? find_id.first_name : "";
      var sobrenome = find_id && find_id.last_name ? find_id.last_name : "";

      if (name.length === 0 || sobrenome.length === 0) {
        return "Sem Vendedor";
      }
      return `${name} ${sobrenome}`;
    },
    async updateLeadsToView(lead) {
      this.lead_click = [];
      this.ctrl_item = [];
      this.loading_fetching = true;
      var result = this.pipelines.find(
        (x) => x.id == this.selectedPipeline
      ).tags;
      if (lead) {
        for (const listName in this.all_leads) {
          if (Object.hasOwnProperty.call(this.all_leads, listName)) {
            const board = this.all_leads[listName];
            if(board){
              let index = board.data.findIndex(x => x.id === lead.id);
              if (index !== -1) {
                  this.all_leads[listName].data.splice(index, 1, lead);
              }
            }
          }
        }
      }else{
        var final = await this.fetchLeads(result, this.search, false, 1);
        this.all_leads = final;
      }
      this.loading_fetching = false;
    },
    async reloadCRM() {
      let result = this.pipelines.find(
        (x) => x.id == this.selectedPipeline
      );

      if(!result){
        return;
      }

      await this.setAllColuns(result.id);
      this.saveDate();
    },
    fetchForms() {
      serviceForm.read({ id: "?global_scope=true&status=active" }).then((resp) => {
        this.forms = resp.data;
      });
    },
    async newPagePipelines(event, search, page = 1, is_callback) {
  const cachedData = await getCachedData(
    this.changeCompanylead ? "fetchPipelineCompanie" : "fetchPipeline"
  );

  if (cachedData && page < cachedData.last_page) {
    await this.fetchPipeline(event, search, page, is_callback);
    return;
  }

  if (typeof servicePipeline.search === 'function') {
    const pipelines = await servicePipeline.search({
      page: page,
    });
    
    if (pipelines.data.length > 0) {
      this.renderPipe(pipelines, page, true);
    }
  } else {
    await this.fetchPipeline(event, search, page, is_callback);
  }
},
    async fetchPipeline(event, search, page = 1, is_callback) {
      if (event === "seller" || event === "search") {
        await this.saveDate();
        await this.updateLeadsToView(search);
      } else {
        const fetchData = async () => {
          try {

            const resp = await servicePipeline.search({
              page: page,
              per_page: 100,
            });
            if (resp.data.length < resp.per_page) {
              this.stop = true;
            }
            if (this.changeCompanylead) {
              cacheData("fetchPipelineCompanie", resp);
            } else {
              cacheData("fetchPipeline", resp);
            }
            this.renderPipe(resp, page, is_callback);
          } catch (err) {
            // Lida com erros, se necessário.
          } finally {
            this.loading = false;
            this.saveDate();
          }
        };

        if (event === "reload") {
          this.loading_fetching = true;
          await fetchData();
          return;
        }
        const cachedData = await getCachedData(
          this.changeCompanylead ? "fetchPipelineCompanie" : "fetchPipeline"
        );

        if (cachedData && this.stop) {
          this.renderPipe(cachedData, page, is_callback);
          this.saveDate();
        }

        if (!this.stop) {
          await fetchData();
        }
      }
    },
    async renderPipe(resp, page, is_callback) {
      if (resp && resp.data && resp.data.length === 0 && page == 1) {
        this.openModal("ModalPipelineAdicionar");
        this.loading = false;
        return;
      }

      if (resp.data && is_callback) {
        let existingIds = new Set(this.pipelines.map(item => item.id));
        resp.data.forEach(newItem => {
            if (!existingIds.has(newItem.id)) {
                this.pipelines.push(newItem);
            }
        });
      }
      if (resp.data && resp.data.length && this.selecionarPipeline === "") {
        this.selecionarPipeline = resp.data[0].title;
      }

      if (this.pipelines && this.pipelines.length && this.$route.params.id) {
        var exist_id = this.pipelines.find(
          (pipe) => pipe.id == this.$route.params.id
        );

        var prev_selectedPipeline = localStorage.getItem(
          "prev_selectedPipeline"
        );
        var selectedPipeline = localStorage.getItem("selectedPipeline");

        if (!exist_id) {
          var exist_localStorage =
            selectedPipeline && selectedPipeline != null
              ? selectedPipeline
              : prev_selectedPipeline;

          if (exist_localStorage) {
            var prev_exist_id = this.pipelines.find(
              (pipe) => pipe.id == exist_localStorage
            );

            if (prev_exist_id) {
              this.selectedPipelineItem = prev_exist_id;
            } else {
              this.selectedPipelineItem = this.pipelines[0];
            }
          } else {
            this.selectedPipelineItem = this.pipelines[0];
          }
        } else {
          this.selectedPipelineItem = exist_id;
        }
      } else {
        this.selectedPipelineItem = this.pipelines[0];
      }

      this.loading_fetching = false;
    },
    async setAllColuns(event) {
      this.loading = true;
      this.all_leads = null;
      var result = this.pipelines.find((x) => x.id == event).tags;
      var final = await this.fetchLeads(result);
      this.all_leads = final;
      // setTimeout(() => {
      this.loading = false;
      // }, 1000);
    },
    async fetchConfigSeller() {
      const cachedData = await getCachedData("fetchConfigSeller");

      if (cachedData) {
        this.sellerConfig = cachedData;
        for (let i = 0; i < cachedData.length; i++) {
          const seller = cachedData[i];
          if (seller.meta_key === "seller_can_transfer_lead") {
            this.configSellerCanTransferLead = seller.meta_value;
          }
        }
      }

      var data = {
        id: "seller",
      };
      serviceSettings.read(data).then((resp) => {
        cacheData("fetchConfigSeller", resp);
        this.sellerConfig = resp;
        for (let i = 0; i < resp.length; i++) {
          const seller = resp[i];
          if (seller.meta_key === "seller_can_transfer_lead") {
            this.configSellerCanTransferLead = seller.meta_value;
          }
        }
      });
    },
    openModalCardSeller2(data, item, list) {
      this.attr_list_seller = list;
      this.seller_selected_card = item;
      this.$bvModal.show(data);
    },
    openModal(data, item) {
      if (item && data === "MoverLead") {
        this.totalPagina = 9999;

        this.updateLeadsToView();
      }
      this.$bvModal.show(data);
      this.valor_tag_colum = parseInt(item);
      this.leadsBoard = item;
    },
    openModalIdLead(modal, lead) {
      this.id_lead_selected = lead;
      this.$grModal.show(modal, this);
    },
    openModalWhatsCtrl(data, lead) {
      this.$bvModal.show(data);
    },
    async insertPhotoAllUser() {
      return new Promise((resolve, reject) => {
        let sellers = this.users;
        if (sellers) {
          for (let i = 0; i < sellers.length; i++) {
            const seller = sellers[i];
            this.allMetaphotoUser.push({
              email: seller.email,
              photo: false,
            });
            for (let x = 0; x < seller.metas.length; x++) {
              const metas = seller.metas[x];
              if (metas.meta_key == "photo") {
                if (typeof metas.meta_value === "string") {
                  this.allMetaphotoUser[i].photo = metas.meta_value;
                  resolve(true);
                }
              }
            }
          }
        } else {
          reject("Not users");
        }
      });
    },
    async initPipe() {
      try {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        await this.fetchUsers();
        this.fetchConfigSeller();
        // this.sellerConfig = JSON.parse(localStorage.getItem("seller"));
        await this.buscarTagsApi();
        // await this.fetchPipeline("first");
        await this.fetchConfigPipeline();
        this.getMe();
        this.fetchForms();
        this.getProduct();
        await this.insertPhotoAllUser();
        } catch (error) {}
    },
  },
  created() {

    if (!this.isIframe) {
      this.user = this.$store.getters.user.user
      this.level = this.$store.getters.user.user.level
      this.recursos = this.$store.getters.recursos
    } else {
      this.recursos = this.recursosData
      this.user = this.userData
      this.level = this.userLevelData
      this.tagList = this.tagsData
      this.products = this.productsData
      this.fetchConfigPipeline()
    }
    setupDB()
      .then(() => {
        this.initPipe();
      })
      .catch((error) => {
        this.initPipe();
      });

      this.showOrignForm();

    if (!this.isIframe && !this.recursos.ncrm) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    if (localStorage.getItem("crmColors") == "false") {
      this.colors = false;
    } else {
      this.colors = true;
    }
    serviceSettings.read('meta/add_tag_pipeline').then((r) => {
      if(r.meta_value === 'true'){
        this.add_tag_pipeline = 'true'
      }
    });
    let body = document.body;
    body.classList.add("notification-pipeline");
  },
  destroyed() {
    let body = document.body;
    body.classList.remove("notification-pipeline");
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.off-colors {
  .colx {
    background-color: #efefef !important;
  }
  .colx:nth-child(2n) {
    background-color: #fbfbfb !important;
  }
  .headerx {
    background-color: #0001 !important;
    position: relative;
    &::before {
      width: 100%;
      position: absolute;
      content: "";
      margin-left: -19px;
      height: 100%;
      opacity: 0.2;
    }
    &.info {
      &::before {
        background-color: var(--bluee);
      }
      * {
        color: var(--bluee);
      }
    }
    &.danger {
      &::before {
        background-color: var(--red);
      }
      * {
        color: var(--red);
      }
    }
    &.warning {
      &::before {
        background-color: var(--orange);
      }
      * {
        color: var(--orange);
      }
    }
    &.primary {
      &::before {
        background-color: var(--greenn);
      }
      * {
        color: var(--greenn);
      }
    }
    &.success {
      &::before {
        background-color: #4b2cbd;
      }
      * {
        color: #4b2cbd;
      }
    }
    &.default {
      &::before {
        background-color: var(--gray02);
      }
      * {
        color: var(--gray02);
      }
    }
  }

  .list-group-item[data-v-9e0f67e2] {
    box-shadow: 0px 2px 8px #0001;
  }
}
.drop-product {
  position: absolute;
  max-height: 800px;
  overflow-y: auto;
  background-color: var(--white-light);
  border-radius: 0 10px 10px 10px;
  padding: 15px;
  width: 270px;
  box-shadow: 2px 2px 10px #0002;
  .apexcharts-tooltip-series-group {
    color: white;
  }
}
.add-colx {
  background-color: #cfcfcf;
  .headerx {
    background: #b4b4b4;
    span,
    h1 {
      cursor: pointer;
    }
  }
  .add-tag {
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
      width: 50px;
      background-color: #e2e2e2;
      border-radius: 50px;
      padding: 10px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-color: #dadada;
        scale: 1.1;
      }
    }
  }
}
.row {
  align-items: center;
}
.is-green::before {
  content: "";
  width: 6px;
  height: 6px;
  background: var(--greenn);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pd {
  padding-right: 0;
  padding-left: 0;
}

.break {
  word-break: break-all;
}


.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}


.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}

.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}

.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  top: -35px;
  left: 16px;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 36px;
  top: -13px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  position: relative;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

@media screen and (max-width: 768px) {

  .flex-sale {
    display: block;
  }

  .flex-sale div {
    margin-left: 0;
  }

  .flex-acoes {
    margin: 15px 0;
  }

  .container-pesquisa {
    text-align: left;
  }

  .search {
    left: 20px;
    top: 22px;
  }

  .input-busca {
    width: 100%;
    position: relative !important;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}

.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  color: var(--greenn);
}

.whats-cliente svg {
  cursor: pointer;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.Table-body2 {
  min-height: 75px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 30px;
  background: #fff;
  border-radius: 0;
}

.Table-body2::before {
  left: 14px;
}


// .button {
//   width: 179px;
//   height: 55px;
//   border-radius: 10px;
//   background: #f7f7f7;
//   color: var(--gray01);
//   font-size: 15px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .button--black {
//   background: var(--gray01);
//   color: #fff;
// }

// .button:hover {
//   transform: scale(1);
// }

.inverted--black {
  filter: invert(100);
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}

.title-report-margin-top {
  margin-top: 41px;
}

.title-report-margin-bottom {
  margin-top: 41px;
}

// .default {
//   height: 19px;
//   padding: 5px;
//   overflow-x: hidden;
//   font-weight: 600;
//   font-size: 10px;
//   color: #000;
//   background: #0000003b;
//   border-radius: 3px;
//   white-space: nowrap;
// }

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}

.all-leads-selected {
  display: flex;
  font-weight: 600;
  font-size: 14px;
}

.cadastro-type {
  padding-bottom: 6px;
}
.separete {
  display: flex;
  flex-direction: column;
  .separete-sub {
    font-weight: 400;
  }
}
.pipe_listagem {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 0 10px;
}

.pipe_listagem::-webkit-scrollbar-thumb {
  background-color: transparent;
  // border-radius: 20px;
}
.pipe_listagem::-webkit-scrollbar-track {
  background-color: transparent;
}
.pipe_listagem::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.listagem-pipe {
  cursor: pointer;
}
.Spinning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

op-container {
  #dropView {
    z-index: 2 !important;
  }
  .qntd-lead {
    position: absolute;
    font-weight: 500;
    top: 3px;
    right: 50px;
    display: flex;
    gap: 5px;
  }
}
.user_logged {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  background-color: #fff !important;
}
.inputSearch input {
  width: 244px;
}

.inputSearch img {
  bottom: 10px;
}
.input-buscaMobile {
  display: flex;
  width: 195px;
  height: 45px !important;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;
  font-weight: 400;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
}
.input-busca {
  display: flex;
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 30px;
  border: none;
  font-weight: 400;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
  position: absolute;
  width: 350px;
  top: 12px;
  left: 360px;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.vendedor {
  position: relative;
  border-bottom: 1px solid #d6d6d6;
}
.vendedor::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--greenn);
  border-radius: 50%;
  top: 20px;
  right: 50px;
}
.drop-position {
  position: relative;
}
.sellerAtivoFoco {
  box-shadow: rgb(255, 255, 255) 0px 0 0 3px !important;
  transform: scale(0.9);
}
.sellerAtivo {
  .container-seller-img {
    img {
      padding: 5px;
    }
  }
}
.sellerAtivo::before {
  content: "";
  font-weight: 750;
  width: 10px;
  height: 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: -20px;
  z-index: 3;
}
.seller-no-ft h3 {
  margin: 0px !important;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.seller-no-ft2 h3 {
  color: transparent;
  font-weight: 600;
  text-align: center;

  font-size: 12px;
  padding-top: 7px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.seller-no-ft {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #81858e;
  // box-shadow: #fff 0px 0 0 3px;
  padding: 5px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.seller-no-ft2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  // box-shadow: #fff 0px 0 0 3px;
  padding: 5px;
  position: relative;
}
.NT {
  display: none;
}
.container-seller-img {
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute;
}
.container-seller-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.sellers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.3s;

  .col{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.container-sellers {
  height: calc(100% - 76px);
  width: 75px;
  background: #2d3438;
  position: fixed;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 76px;
  z-index: 99;
  padding: 0 0 5px 0;
  .spacer {
    margin: 0;
    background: #525d64;
  }
}
.container-sellers::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}
.container-sellers::-webkit-scrollbar-track {
  background-color: transparent;
}
.container-sellers::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
//ADICIONAR-LEAD
.add-lead-bottom {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #fff;
  border: 2.2px solid #4d4d4d11;
  border-radius: 50%;
  cursor: pointer;
  margin: 10px auto;
  margin-bottom: 20px;
}
// BOTAO DE ACAO
.acoes-lead-companie h5,
.acoes-remove h5,
.acoes-mover h5,
.acoes-pipe h5,
.acoes-filtro h5,
.acoes-visualizar h5 {
  margin: 0px;
  font-weight: 600;
  font-size: 13px;
}
.acoes-visualizar,
.acoes-lead-companie,
.acoes-remove,
.acoes-mover,
.acoes-pipe,
.acoes-filtro,
.listagem-pipe,
.acoes-config,
.acoes-notificacao,
.acoes-busca,
.acoes-relatorio,
.acoes-notfy {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.acoes-lead-companie:hover,
.acoes-remove:hover,
.acoes-mover:hover,
.acoes-pipe:hover,
.acoes-filtro:hover,
.listagem-pipe:hover,
.acoes-visualizar:hover,
.acoes-config:hover,
.acoes-notificacao :hover,
.acoes-busca:hover,
.acoes-relatorio:hover,
.acoes-notfy:hover {
  transform: scale(1.07);
}
select {
  height: 60px !important;
}
input {
  margin: 0px !important;
  height: 60px !important;
}
.acoes-select {
  width: 270px !important;
}
.acoes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  z-index: 56;
  height: 75px;
}
.acoesMobile {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // border-bottom: 1px solid #e9ecef;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 50px;
  padding: 5px 0px 5px 12px;
  z-index: 6;
}
.acoes-input {
  padding: 10px;
}
.cont-acoesMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cont-acoes {
  display: flex;
  align-items: center;
}
.cont-acoes:nth-child(1) {
  gap: 20px;
}
.cont-acoes:nth-child(2) {
  gap: 50px;
}
.acoes-loading {
  position: relative;
}
// COLUNAS DRAG AND DROP
.baseMobile {
  width: 100%;
  display: flex;
  position: absolute;
  top: 60px;
}
.base {
  width: calc(100vw - 70px);
  height: calc(100vh - 76px);
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  position: absolute;
  top: 76px;
}

.background-vertical {
  background: repeating-linear-gradient(
    90deg,
    rgb(245, 245, 245),
    rgb(245, 245, 245) 300px,
    #e6e6e6 300px,
    #e6e6e6 600px
  );
  .background-header {
    position: absolute;
    height: 60px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.027);
    z-index: 0;
  }
}
.base::-webkit-scrollbar-thumb {
  background-color: #2d3438;
  border-radius: 20px;
}
.base::-webkit-scrollbar-track {
  background-color: var(--bluee);
}
.base::-webkit-scrollbar {
  height: 10px !important;
  background: var(--bluee);
}
.flex {
  display: flex;
  gap: 30px;
  align-items: center;
}
.headerxMobile {
  z-index: 6;
  position: fixed;
  width: 100%;
}
.headerx {
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 19px 19px;
  display: flex;
  align-items: center;
  z-index: 55;
  position: relative;

  .numberLeads {
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    box-shadow: inset 0 0 0 12px #ffffff1f;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align-last: center;
    line-height: 27px;
  }
  .moneyTotal {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }

  h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin: 0;
  }
  .color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin-right: 10px;
  }
}
.headerx:hover {
  .drop-info {
    display: block;
  }
}
.drop-info {
  display: block;
}
.red-item {
  color: red;
}
.colxMobile {
  width: 100vw;
  overflow-y: hidden;
}
.colx {
  min-width: 300px !important;
  width: 300px;
  height: calc(100vh - 76px);
  overflow-y: hidden;
}
.list-groupMobile {
  overflow-y: auto;
  padding-bottom: 70px;
  position: relative;
  height: calc(100vh - 76px);
}
.list-group {
  min-height: 50vh;
  overflow-y: auto;
  height: calc(100% - 60px);
  padding-bottom: 5px;
  padding-top: 5px;
  position: relative;
}
.list-group::-webkit-scrollbar-thumb {
  background-color: #e9e9e99f;
  border-radius: 20px;
}
.list-group::-webkit-scrollbar-track {
  background-color: transparent;
}
.list-group::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.list-group-item {
  cursor: grab;
  margin: 8px 8px 0px 8px;
  padding: 20px;
  border-radius: 10px !important;
  border: none;
  transition: 0s;
  position: relative;
}
.list-group-item-fake {
  cursor: default;
  margin: 8px 8px 0px 8px;
  padding: 20px;
  border-radius: 10px !important;
  border: none;
  transition: 0s;
  user-select: none;
  div {
    height: 150px;
  }
}

.selected {
  box-shadow: inset 0 0 0 3px #2182d2;
}

.ghost {
  opacity: 0.3;
}
// ITEM DRAG AND DROP
.color-alert {
  color: var(--orange-dark);
  fill: var(--orange-dark)
}
.color-red {
  color: var(--red);
  fill: var(--red)
}
.color-greenn{
  color: var(--greenn);
  fill: var(--greenn)
}
.color-gray{
  color: var(--gray03);
  fill: var(--gray03)
}
.img {
  cursor: pointer;
}

// .name-card-seller {
//   font-weight: 500;
//   font-size: 16px;
//   color: #595959;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 250px;
//   line-height: 20px;
//   padding-right: 8px;
//   margin: 0;
//   border-radius: 3px;
// }

.name-seller {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
}

// TAGS

.info {
  background: var(--bluee);
  .headerx {
    background: var(--blue-dark);
  }
}
.danger {
  background: var(--red);
  .headerx {
    background: var(--red-dark);
  }
}
.warning {
  background: var(--orange);
  .headerx {
    background: var(--orange-dark);
  }
}
.primary {
  background: var(--greenn-light);
  .headerx {
    background: var(--greenn);
  }
}
.success {
  background: #4b2cbd;
  .headerx {
    background: var(--bluee);
  }
}
.default {
  background: var(--gray02);
  .headerx {
    background: var(--gray01);
  }
}

// TAGS LABEL

.tag-label {
  padding: 5px;
  border-radius: 10px;
  display: inline-block;
  width: 90px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lds-ellipsis {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>

<style>
body:has(.flex-pipe) div:has(#crowd-shortcut) {
  z-index: -2 !important;
  opacity: 0 !important;
}
</style>
